@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
